import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Review from "./Review";

const RestaurantSlider = ({ reviews }) => {
  const groupedReviews = reviews.reduce((acc, review) => {
    const restaurantName = review.data.restaurant;
    if (!acc[restaurantName]) {
      acc[restaurantName] = [];
    }
    acc[restaurantName].push(review);
    return acc;
  }, {});

  return (
    <Box className="restaurant-slider" sx={{ width: "auto" }}>
      {Object.entries(groupedReviews).map(([restaurantName, reviews]) => (
        <Box key={restaurantName} className="slider-container">
          <Typography className="slider-name">{restaurantName}</Typography>
          <Box
            className="reviews-container"
            sx={{
              width: "auto",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
                scrollbarWidth: "none",
              },
            }}
          >
            {reviews.map((review) => (
              <Box sx={{ m: 2, overflowX: "wrap" }}>
                <Review key={review.id} reviewData={review.data} />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default RestaurantSlider;
