import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import BottomNavBar from "../Components/BottomNavbar";
import CitySearchBar from "../Components/CitySearchBar";
import RestaurantSlider from "../Components/RestaurantSlider";
import Box from "@mui/material/Box";

const FindMyFoodPage = () => {
  const [reviews, setReviews] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (selectedCity) {
      const [cityName, stateName] = selectedCity
        .split(", ")
        .map((s) => s.trim());
      const fetchReviews = async () => {
        const reviewsRef = db.collection("reviews");
        const querySnapshot = await reviewsRef
          .where("city", "==", cityName)
          .where("state", "==", stateName)
          .get();

        const fetchedReviews = [];
        querySnapshot.forEach((doc) => {
          fetchedReviews.push({ id: doc.id, data: doc.data() });
        });
        setReviews(fetchedReviews);
      };

      fetchReviews();
    }
  }, [selectedCity]);

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setShowResults(true);
  };

  return (
    <Box>
      {showResults ? (
        <Box sx={{ m: 4, fontSize: 20, color: "#e85a4f" }}>
          Not seeing enough reviews? Invite others to TasteBuds and help us grow
          our community of food lovers!
        </Box>
      ) : (
        <Box sx={{ m: 4, fontSize: 20, color: "#e85a4f" }}>
          Craving something delicious? Enter your city to discover top dining
          spots!
        </Box>
      )}

      <CitySearchBar onCitySelect={handleCitySelect} />

      {showResults && (
        <Box>
          <RestaurantSlider reviews={reviews} />
        </Box>
      )}
      <BottomNavBar />
    </Box>
  );
};

export default FindMyFoodPage;
