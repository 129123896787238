import React from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../Context/AuthContext";
import Button from "@mui/material/Button";
import "./css/Navbar.css"; // Import your stylesheet

const Navbar = () => {
  const { user, logOut } = UserAuth();

  const handleSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="navbar">
      <Link
        to={user ? "/home-feed" : ""}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <h1>TasteBuds</h1>
      </Link>

      {user ? (
        <Button
          variant="contained"
          size="sm"
          className="auth-button"
          onClick={handleSignOut}
        >
          Sign Out
        </Button>
      ) : (
        <Link to="/signin" className="auth-button sign-in">
          Sign In
        </Link>
      )}
    </div>
  );
};

export default Navbar;
