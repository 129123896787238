import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

// // // Define the callback function
// function initMap() {}

// // Include Google Maps API script with the callback
// const script = document.createElement("script");
// script.async = true;
// script.defer = true;
// script.src =
//   "https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places&callback=initMap";

// document.head.appendChild(script);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
