import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase";
import { FaStar } from "react-icons/fa";
import { Box, Typography, Avatar } from "@mui/material";
import TagsDisplay from "./TagsDisplay";
import "./css/Review.css";

const Review = ({ reviewData }) => {
  const [username, setUsername] = useState(null);
  const [userImg, setUserImg] = useState(null);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const userDoc = await db
          .collection("users")
          .doc(reviewData.userUID)
          .get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          setUsername(userData.handle);
          setUserImg(userData.photoURL);
        } else {
          console.log("User document not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUsername();
  }, [reviewData.userUID]);

  return (
    <Box className="review">
      <Box className="top-row">
        <Link to={`/account/${reviewData.userUID}`} className="username-link">
          <Avatar className="user-img" src={userImg} alt="user" />
          <Typography style={{ fontWeight: 900 }} className="username">
            {username}{" "}
          </Typography>
        </Link>
        <Box className="rating-box">
          <Typography className="rating" style={{ fontWeight: 900 }}>
            {reviewData.rating}
          </Typography>
          <FaStar className="star" />
        </Box>
      </Box>
      {reviewData.photoURL && (
        <img className="photo" src={reviewData.photoURL} alt="Review" />
      )}
      {!reviewData.photoURL && <div className="separator"></div>}
      <Box className="restaurant-info">
        <Typography style={{ fontWeight: 900 }}>
          {reviewData.restaurant} • {reviewData.city}, {reviewData.state}
        </Typography>
      </Box>
      <Box>
        <Typography className="item-name">
          {reviewData.itemPurchased}
        </Typography>
      </Box>
      <Box className="tags-box">
        <TagsDisplay tags={reviewData.tags} />
      </Box>
      <Box>
        <Typography className="review-text">{reviewData.review}</Typography>
      </Box>
    </Box>
  );
};

export default Review;
