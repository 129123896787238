import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { Avatar } from "@mui/material";
import Button from "@mui/joy/Button";
import { Box } from "@mui/material";
import BottomNavBar from "../Components/BottomNavbar";
import Review from "../Components/Review";
import RestaurantCard from "../Components/RestaurantCard.jsx";
import RestaurantModal from "../Components/RestaurantModal";
import "./css/UserAccount.css";

const Account = () => {
  const { userUID } = useParams();
  const [userData, setUserData] = useState(null);
  const [userReviews, setUserReviews] = useState([]);
  const [activeSection, setActiveSection] = useState("reviews");
  const [showModal, setShowModal] = useState(false);
  const [clickedRestaurant, setClickedRestaurant] = useState(null);

  useEffect(() => {
    if (userUID) {
      const userRef = db.collection("users").doc(userUID);

      userRef.get().then((doc) => {
        if (doc.exists) {
          setUserData(doc.data());
        }
      });
    }
  }, [userUID]);

  const username = userData ? userData.display_name : "";
  const userHandle = userData ? userData.handle : "";
  let userBio = userData ? userData.bio : "";

  useEffect(() => {
    if (userUID) {
      const reviewsRef = db
        .collection("reviews")
        .where("userUID", "==", userUID)
        .orderBy("timestamp", "desc");

      reviewsRef.get().then((querySnapshot) => {
        const reviews = [];
        querySnapshot.forEach((doc) => {
          reviews.push({ id: doc.id, data: doc.data() });
        });
        setUserReviews(reviews);
      });
    }
  }, [userUID]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const uniqueRestaurantNames = Array.from(
    new Set(userReviews.map((review) => review.data.restaurant))
  );

  const restaurantCounts = uniqueRestaurantNames.reduce(
    (counts, restaurant) => {
      const count = userReviews.filter(
        (review) => review.data.restaurant === restaurant
      ).length;
      counts[restaurant] = count;
      return counts;
    },
    {}
  );

  const handleRestaurantClick = (restaurantName) => {
    setClickedRestaurant(restaurantName);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setClickedRestaurant(null);
  };

  return (
    <div>
      <div className="user-profile">
        {userData && (
          <Avatar
            className="profile-picture"
            src={userData.photoURL}
            alt="User Profile"
          />
        )}
        <div className="user-details">
          <h1 className="user-name">{username}</h1>
          <p className="user-handle">@{userHandle}</p>
          <p className="user-bio">{userBio}</p>
        </div>
      </div>

      {/* Navigation Bar */}
      <div className="nav-bar">
        <Button
          variant="plain"
          className={activeSection === "reviews" ? "active" : ""}
          onClick={() => handleSectionChange("reviews")}
        >
          Reviews
        </Button>
        <Button
          variant="plain"
          className={activeSection === "restaurants" ? "active" : ""}
          onClick={() => handleSectionChange("restaurants")}
        >
          Restaurants
        </Button>
      </div>

      {/* Display user's reviews or restaurants based on the active section */}
      <div className="user-content">
        {activeSection === "reviews" && (
          <div className="user-reviews">
            {userReviews.map((review) => (
              <Review key={review.id} reviewData={review.data} />
            ))}
          </div>
        )}
        {activeSection === "restaurants" && (
          <div className="user-restaurants">
            {uniqueRestaurantNames.map((restaurantName, index) => (
              <div
                key={index}
                onClick={() => handleRestaurantClick(restaurantName)}
              >
                <RestaurantCard
                  restaurantName={restaurantName}
                  number={restaurantCounts[restaurantName]}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {showModal && clickedRestaurant && (
        <RestaurantModal
          onClose={handleCloseModal}
          restaurantName={clickedRestaurant}
          reviews={userReviews.filter(
            (review) => review.data.restaurant === clickedRestaurant
          )}
        />
      )}

      <BottomNavBar />
    </div>
  );
};

export default Account;
