import React from "react";
import "./css/RestaurantCard.css";

const RestaurantCard = ({ restaurantName, number }) => {
  return (
    <div className="restaurant-card">
      <p className="number-badge">{number}</p>
      <p>{restaurantName}</p>
    </div>
  );
};

export default RestaurantCard;
