import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./Context/AuthContext";
import Navbar from "./Components/Navbar";
import Protected from "./Components/Protected";
import WelcomePage from "./Pages/WelcomePage";
import RegisterPage from "./Pages/Register";
import SignInPage from "./Pages/SignInPage";
import UserAccount from "./Pages/UserAccount";
import Account from "./Pages/Account";
import AddRestaurantPage from "./Pages/addRestaurant";
import FindMyFoodPage from "./Pages/FindMyFoodPage";
// import HomeFeed from "./Pages/HomeFeed";
import SearchPage from "./Pages/SearchPage";
import EditProfilePage from "./Pages/EditProfilePage";
import ReviewPage from "./Pages/ReviewPage";
import FAQPage from "./Pages/FAQPage";
import "./App.css";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route path="" element={<WelcomePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/account/:userUID" element={<Account />} />
          <Route path="/register-restaurant" element={<AddRestaurantPage />} />
          <Route
            path="/user-account"
            element={
              <Protected>
                <UserAccount />
              </Protected>
            }
          />
          <Route
            path="/home-feed"
            element={
              <Protected>
                <FindMyFoodPage />
              </Protected>
            }
          />
          <Route
            path="/user-account/edit"
            element={
              <Protected>
                <EditProfilePage />
              </Protected>
            }
          />
          <Route
            path="/post-review"
            element={
              <Protected>
                <ReviewPage />
              </Protected>
            }
          />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
