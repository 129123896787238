import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD56R7zRCG2MabchuxMGmI3u7ZdBMTx6Yo",
  authDomain: "tastebuds-91042.firebaseapp.com",
  projectId: "tastebuds-91042",
  storageBucket: "tastebuds-91042.appspot.com",
  messagingSenderId: "938398133850",
  appId: "1:938398133850:web:eda2c639a98404b4adf263",
  measurementId: "G-JDMMCQSCJ6",
};

// eslint-disable-next-line no-unused-vars
const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
