import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import CopyLinkButton from "../Components/CopyLinkButton";
import EditProfileModal from "../Components/EditProfileModal";

const ProfileHead = ({ user, userData }) => {
  return (
    <Card
      className="user-profile"
      elevation={0}
      sx={{
        bgcolor: "#eae7dc",
        maxWidth: "60vw",
        textAlign: "center",
      }}
    >
      {user && userData && (
        <CardContent sx={{ padding: "16px 8px" }}>
          <Avatar
            className="profile-picture"
            src={userData.photoURL || ""}
            alt="User Profile Picture"
            sx={{ width: 80, height: 80, mx: "auto", marginBottom: "0px" }}
          />
          <Box
            component="h3"
            sx={{
              fontSize: 16,
              fontWeight: "bold",
              letterSpacing: "0.5px",
              margin: "0",
            }}
          >
            <p className="user-name">{userData.display_name}</p>
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: 13,
              color: "blue",
              marginBottom: "0",
            }}
          >
            <p className="user-handle">@{userData.handle}</p>
          </Box>
          <Box
            component="span"
            sx={{
              fontSize: 13,
              color: "blue",
              marginBottom: "8px",
            }}
          >
            <p className="user-bio">{userData.bio || ""}</p>
          </Box>
        </CardContent>
      )}
      <Divider light />
      <Box display={"flex"} sx={{ padding: "8px 0" }}>
        <Box
          p={1}
          flex={"auto"}
          sx={{
            position: "relative",
            "&:not(:last-of-type)": {
              "&:after": {
                content: '""',
                display: "block",
                position: "absolute",
                height: "50%",
                width: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
              },
            },
          }}
        >
          <Box
            sx={{
              fontSize: 11,
              color: "grey.500",
              fontWeight: 500,
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              margin: 0,
            }}
          >
            <EditProfileModal user={user} userData={userData} />
          </Box>
        </Box>
        <Box
          p={1}
          flex={"auto"}
          sx={{
            position: "relative",
            "&:not(:last-of-type)": {
              "&:after": {
                content: '""',
                display: "block",
                position: "absolute",
                height: "50%",
                width: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                top: "50%",
                right: 0,
                transform: "translateY(-50%)",
              },
            },
          }}
        >
          <Box
            sx={{
              fontSize: 11,
              color: "grey.500",
              fontWeight: 500,
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
              margin: 0,
            }}
          >
            <CopyLinkButton />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ProfileHead;
