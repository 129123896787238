import React from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Box,
} from "@mui/material";

// Define your tags options
const tags = [
  { value: "locallyOwned", label: "Locally Owned" },
  { value: "vegetarianOptions", label: "Vegetarian Options" },
  { value: "veganOptions", label: "Vegan Options" },
  { value: "kidFriendly", label: "Kid Friendly" },
  { value: "openLate", label: "Open Late" },
  { value: "openEarly", label: "Open Early" },
  { value: "socialize", label: "Socialize" },
  { value: "readStudy", label: "Read/Study" },
];

// Define styles for selected items
// function getStyles(tag, selectedTags, theme) {
//   return {
//     fontWeight:
//       selectedTags.indexOf(tag) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

// TagSelect component
const TagSelect = ({ selectedTags, onChange }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="tags-select-label">Tags</InputLabel>
      <Select
        labelId="tags-select-label"
        id="tags-select"
        multiple
        value={selectedTags}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Tags" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={tags.find((tag) => tag.value === value)?.label}
              />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
            },
          },
        }}
      >
        {tags.map((tag) => (
          <MenuItem key={tag.value} value={tag.value}>
            {tag.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TagSelect;
