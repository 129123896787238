import React, { useState } from "react";
import { UserAuth } from "../Context/AuthContext";
import { db, storage } from "../firebase";
import { useNavigate } from "react-router-dom";
// import { Autocomplete } from "@react-google-maps/api";
import { TextField } from "@mui/material";
import StateSelect from "./StateSelect";
import TagSelect from "./TagSelect";
import PhotoUpload from "./PhotoUpload";

const PostReview = () => {
  const { user } = UserAuth();
  const userUID = user.uid;
  const navigate = useNavigate();
  // const apiKey = process.env.REACT_APP_API_KEY;

  // const [, setPlace] = useState(null);
  // const [autocomplete, setAutocomplete] = useState(null);
  const [restaurant, setRestaurant] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState(null);
  const [itemPurchased, setItemPurchased] = useState("");
  const [tags, setTags] = useState([]);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [photoFile, setPhotoFile] = useState(null);
  const [photoURL, setPhotoURL] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNavigation = () => {
    navigate("/home-feed");
  };

  const handleSelectedStateStateChange = (e) => {
    const state = e.target.value;
    setState(state);
  };

  const handleSelectedTagsStateChange = (newTags) => {
    setTags(newTags);
  };

  // const handlePlaceSelect = (place) => {
  //   setPlace(place);
  //   setRestaurant(place.name);
  //   setCity(
  //     place.address_components.find((c) => c.types.includes("locality"))
  //       .long_name
  //   );
  //   setState(
  //     place.address_components.find((c) =>
  //       c.types.includes("administrative_area_level_1")
  //     ).short_name
  //   );
  // };
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (
      restaurant &&
      city &&
      state &&
      itemPurchased &&
      review &&
      rating !== 0 &&
      userUID
    ) {
      try {
        setLoading(true);
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        let photoDownloadURL = null;

        // Check if a photo has been selected
        if (photoFile) {
          // If a photo is selected, upload it to Firebase Storage
          const storageRef = storage.ref();
          const filename = `${userUID}-${Date.now()}-${photoFile.name}`;
          const photoRef = storageRef.child(filename);
          await photoRef.put(photoFile);
          photoDownloadURL = await photoRef.getDownloadURL();
        }

        // Create the review data
        const reviewData = {
          userUID,
          restaurant,
          city,
          state,
          itemPurchased,
          tags,
          review,
          rating,
          photoURL: photoDownloadURL || "",
          timestamp: new Date(),
        };

        // Submit the review to Firestore
        db.collection("reviews")
          .add(reviewData)
          .then(() => {
            console.log("Review submitted successfully");
            setPhotoURL(photoDownloadURL); // Update the state with the photo URL
          })
          .catch((error) => {
            console.error("Error submitting review: ", error);
          });
      } catch (error) {
        console.error("Error uploading photo: ", error);
      } finally {
        setLoading(false);
        handleNavigation();
      }
    } else {
      console.log("Please fill out all required fields");
    }
  };

  return (
    <div className="form-container">
      <h2>Post a Review</h2>
      {loading ? ( // Conditionally render loading content
        <div className="loading-spinner">
          {/* Add your loading spinner or animation here */}
          Loading...
        </div>
      ) : (
        // Render the form when not loading
        <form className="form" onSubmit={handleReviewSubmit}>
          <div className="form-group">
            <TextField
              label="Restaurant Name"
              variant="outlined"
              fullWidth
              id="restaurant"
              value={restaurant}
              onChange={(e) => setRestaurant(e.target.value)}
            />
            {/* <Autocomplete
              apiKey={apiKey}
              onLoad={(autocomplete) => setAutocomplete(autocomplete)}
              onPlaceChanged={() => handlePlaceSelect(autocomplete.getPlace())}
            >
              <input type="text" placeholder="Search for a restaurant" />
            </Autocomplete> */}
          </div>
          <div className="form-group">
            <StateSelect
              selectedState={state}
              onChange={handleSelectedStateStateChange}
            />
          </div>
          <div className="form-group">
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="form-group">
            <TextField
              label="Menu Item"
              variant="outlined"
              fullWidth
              id="itemPurchased"
              value={itemPurchased}
              onChange={(e) => setItemPurchased(e.target.value)}
            />
          </div>
          <div className="form-group">
            <TagSelect
              selectedTags={tags}
              onChange={handleSelectedTagsStateChange}
            />
          </div>
          <div className="form-group">
            <TextField
              label="Your Review"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              id="review"
              value={review}
              onChange={(e) => setReview(e.target.value)}
            />
          </div>
          <div className="form-group">
            <TextField
              label="Select Rating"
              type="number"
              id="rating"
              value={rating}
              variant="outlined"
              onChange={(e) => setRating(parseInt(e.target.value, 10))}
              inputProps={{ min: 1, max: 5, step: 1 }}
              fullWidth
            />
          </div>
          <div className="form-group">
            <PhotoUpload
              setPhotoFile={setPhotoFile}
              setPhotoURL={setPhotoURL}
              photoURL={photoURL}
            />
          </div>
          {/* <label htmlFor="photo">Upload a Photo:</label>
            <input
              type="file"
              id="photo"
              accept="image/*"
              onChange={handlePhotoUpload}
            />
          </div>
          {photoURL && (
            <img src={photoURL} className="review-photo" alt="food" />
          )} */}
          <button type="submit">Submit Review</button>
        </form>
      )}
    </div>
  );
};

export default PostReview;
