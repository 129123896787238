export const tagDefinitions = [
  { value: "locallyOwned", label: "Locally Owned", color: "#ff8c00" },
  { value: "vegetarianOptions", label: "Vegetarian Options", color: "#32cd32" },
  { value: "veganOptions", label: "Vegan Options", color: "#2e8b57" },
  { value: "kidFriendly", label: "Kid Friendly", color: "#ffa500" },
  { value: "openLate", label: "Open Late", color: "#ff4500" },
  { value: "openEarly", label: "Open Early", color: "#4682b4" },
  { value: "socialize", label: "Socialize", color: "#d2691e" },
  { value: "readStudy", label: "Read/Study", color: "#8a2be2" },
];
