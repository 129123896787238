import React from "react";
import { Link } from "react-router-dom";
import { FaCamera, FaHamburger, FaShare } from "react-icons/fa";
import Button from "@mui/material/Button";
import "./css/WelcomePage.css";

function WelcomePage() {
  return (
    <div className="welcome-container">
      <h1>TasteBuds</h1>
      <div className="info-container">
        <p className="slogan-text">snap, eat, share</p>
        <div className="row">
          <div className="row-left">
            <div className="row-left-info">
              <FaCamera className="icon" />
              <p>snap</p>
            </div>
          </div>
          <div className="row-right">
            <p className="row-right-info">
              Snap a photo of your meal before you get started! Don't just
              remember the food, remember the experience!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="row-right">
            <p className="row-right-info">
              Enjoy the anticipation of your favorite meal! Take your time to
              savor every bite and let the flavors dance on your taste buds.
            </p>
          </div>
          <div className="row-left">
            <div className="row-left-info">
              <FaHamburger className="icon" />
              <p>eat</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="row-left">
            <div className="row-left-info">
              <FaShare className="icon" />
              <p>share</p>
            </div>
          </div>
          <div className="row-right">
            <p className="row-right-info">
              Share the joy of your dining experience with friends and family!
              Let them in on your culinary discoveries so they can enjoy the
              goodness too.
            </p>
          </div>
        </div>
      </div>
      <div className="button-container">
        <Link to="/signin">
          <Button variant="contained" className="sign-in-button">
            Sign In
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default WelcomePage;
