import React from "react";
import BottomNavBar from "../Components/BottomNavbar";
import "./css/FAQPage.css";

const FAQPage = () => {
  const faqs = [
    {
      question: "Can I add TasteBuds to the home page of my iphone?",
      answer:
        "Yes! While viewing the website on Safari, tap the Share button in the menu bar. Then scroll down the list of options and tap Add to Home Screen.",
    },
    {
      question:
        "I have some suggestions on features and have encountered some bugs, who can I contact?",
      answer:
        "You can email me at mbdavis916@gmail.com or message me on instagram @mikekwom. TasteBuds is still in early development and I have a long list of things to add but am always looking for more suggestions!",
    },
  ];

  return (
    <div className="faq-section">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="question">
            <strong>Q: {faq.question}</strong>
          </div>
          <div className="answer">
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
      <BottomNavBar />
    </div>
  );
};

export default FAQPage;
