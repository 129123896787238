import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import JoyButton from "@mui/joy/Button";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { storage, db } from "../firebase"; // Adjust imports as needed

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditProfileModal({ user, userData }) {
  const [open, setOpen] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [handle, setHandle] = useState("");
  const [bio, setBio] = useState("");
  const [photo, setPhoto] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    if (userData) {
      setDisplayName(userData.display_name || "");
      setHandle(userData.handle || "");
      setBio(userData.bio || "");
      setPhotoURL(userData.photoURL || "");
    }
  }, [userData]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleSave = async () => {
    if (user) {
      try {
        let newPhotoURL = photoURL;

        // Handle photo upload if a new photo is selected
        if (photo) {
          const storageRef = storage.ref(`profile_photos/${user.uid}`);
          await storageRef.put(photo);
          newPhotoURL = await storageRef.getDownloadURL();
        }

        // Update user document
        await db.collection("users").doc(user.uid).update({
          display_name: displayName,
          handle: handle,
          bio: bio,
          photoURL: newPhotoURL,
        });

        handleClose();
        window.location.reload();
      } catch (error) {
        console.error("Error updating profile: ", error);
      }
    }
  };

  return (
    <div>
      <JoyButton className="profile-btn" variant="solid" onClick={handleOpen}>
        Edit Profile
      </JoyButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title">Edit Profile</Typography>
          <Box sx={{ mt: 2 }}>
            <Avatar
              src={photo ? URL.createObjectURL(photo) : photoURL}
              sx={{ width: 100, height: 100, mb: 2 }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              style={{ marginBottom: "16px" }}
            />
            <TextField
              label="Display Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            <TextField
              label="Handle"
              variant="outlined"
              fullWidth
              margin="normal"
              value={handle}
              onChange={(e) => setHandle(e.target.value)}
            />
            <TextField
              label="Bio"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
            <Box
              sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
