// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../Context/AuthContext";
import { db } from "../firebase";
import "./css/EditProfile.css";

const EditProfile = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/user-account");
  };

  // State and variable declarations using hooks
  const { user } = UserAuth();
  const [userData, setUserData] = useState(null);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [newHandle, setNewHandle] = useState("");
  const [isHandleAvailable, setIsHandleAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      const userRef = db.collection("users").doc(user.uid);

      userRef.get().then((doc) => {
        if (doc.exists) {
          setUserData(doc.data());
          setNewDisplayName(doc.data().display_name);
          setNewHandle(doc.data().handle);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    // Function to check handle availability in real-time
    const checkHandleAvailability = async (handle) => {
      setIsLoading(true);

      // check if handle is empty or contains only whitespace
      const lowerCaseHandle = handle.trim().toLowerCase();

      if (lowerCaseHandle !== "") {
        const handleRef = db
          .collection("users")
          .where("handle", "==", lowerCaseHandle);
        const snapshot = await handleRef.get();
        setIsHandleAvailable(snapshot.empty);
      } else {
        setIsHandleAvailable(false);
      }
      setIsLoading(false);
    };

    // Debounce function to delay handle validation after user stops typing
    const debounce = (fn, delay) => {
      let timeoutId;
      return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          fn(...args);
        }, delay);
      };
    };

    // Validate handle in real-time with a debounce of 500ms
    const validateHandle = debounce((handle) => {
      checkHandleAvailability(handle);
    }, 500);

    // Execute handle validation when the handle changes
    validateHandle(newHandle);

    // Cleanup function to clear the timeout
    return () => clearTimeout(validateHandle);
  }, [newHandle]);

  const handleDisplayNameChange = (event) => {
    setNewDisplayName(event.target.value);
  };

  const saveDisplayName = () => {
    if (user) {
      const userRef = db.collection("users").doc(user.uid);

      userRef
        .update({ display_name: newDisplayName })
        .then(() => {
          setUserData({ ...userData, display_name: newDisplayName });
        })
        .catch((error) => {
          console.error("Error updating display name: ", error);
        });
    }
  };

  const handleHandleChange = (event) => {
    const lowerCaseHandle = event.target.value.toLowerCase();
    setNewHandle(lowerCaseHandle);
  };

  const saveHandle = () => {
    if (user && newHandle.trim() !== "") {
      const lowerCaseHandle = newHandle.toLowerCase();
      const userRef = db.collection("users").doc(user.uid);

      userRef
        .update({ handle: lowerCaseHandle })
        .then(() => {
          setUserData({ ...userData, handle: lowerCaseHandle });
        })
        .catch((error) => {
          console.error("Error updating handle: ", error);
        });
    }
  };

  return (
    <div>
      <div className="edit-section">
        <button className="back-button" onClick={handleNavigation}>
          Back
        </button>
        {user && (
          <div>
            {/* Display user profile picture */}
            <img
              className="image"
              src={userData ? userData.photoURL : ""}
              alt="Click to edit"
              // Add any onClick or editing logic for the profile picture if needed
            />

            {/* Input for changing profile picture */}
            <input
              type="file"
              id="photoInput"
              accept="image/*"
              style={{ display: "none" }}
              // Add onChange handler for changing the profile picture
            />
          </div>
        )}

        {/* User details */}
        <div className="user-details">
          {userData ? (
            <>
              <h1 className="user-name">{userData.display_name}</h1>
              <p className="user-handle">@{userData.handle}</p>
            </>
          ) : (
            <p>Loading...</p>
          )}
          {/* Handle section with real-time validation */}
          <div>
            <label>Display Name:</label>
            <input
              type="text"
              value={newDisplayName}
              onChange={handleDisplayNameChange}
            />
            <button onClick={saveDisplayName}>Save Display Name</button>
          </div>
          <div>
            <label>Handle:</label>
            <input
              type="text"
              value={newHandle}
              onChange={handleHandleChange}
              className={!isHandleAvailable ? "handle-not-available" : ""}
            />
            {isLoading && <p>Loading...</p>}
            {!isHandleAvailable && !isLoading && (
              <p className="error-message">Handle not available</p>
            )}

            {/* Save Handle button - render only when the handle is not empty and available */}
            {newHandle.trim() !== "" && isHandleAvailable && (
              <button onClick={saveHandle}>Save Handle</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
