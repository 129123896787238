import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context/AuthContext";
import { db } from "../firebase";
import Button from "@mui/joy/Button";
import { Box } from "@mui/material";
import ProfileHead from "../Components/ProfileHead";
import Review from "../Components/Review";
import BottomNavBar from "../Components/BottomNavbar";
import RestaurantCard from "../Components/RestaurantCard";
import RestaurantModal from "../Components/RestaurantModal";
import "./css/UserAccount.css";

const UserAccount = () => {
  const [userReviews, setUserReviews] = useState([]);
  const [activeSection, setActiveSection] = useState("reviews");
  const [showModal, setShowModal] = useState(false);
  const [clickedRestaurant, setClickedRestaurant] = useState(null);

  const { user } = UserAuth();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (user) {
      const userRef = db.collection("users").doc(user.uid);

      userRef.get().then((doc) => {
        if (doc.exists) {
          setUserData(doc.data());
          console.log(userData);
        }
      });
    }
  }, [user]);

  // const handlePhotoChange = (e) => {
  //   const file = e.target.files[0];
  //   setPhotoFile(file);

  //   savePhoto();
  // };

  // const savePhoto = () => {
  //   // Check if there's a new photo to upload
  //   if (photoFile) {
  //     // Upload the new photo to storage (you may need to implement this part)
  //     const storageRef = storage.ref(`profile_photos/${user.uid}`);
  //     storageRef.put(photoFile).then((snapshot) => {
  //       // Get the download URL of the uploaded photo
  //       snapshot.ref.getDownloadURL().then((downloadURL) => {
  //         // Update the user document with the new photoURL
  //         const userRef = db.collection("users").doc(user.uid);
  //         userRef
  //           .update({ photoURL: downloadURL })
  //           .then(() => {
  //             // Update the local state with the new photoURL
  //             setUserData({ ...userData, photoURL: downloadURL });
  //           })
  //           .catch((error) => {
  //             console.error("Error updating photoURL: ", error);
  //           });
  //       });
  //     });
  //   }
  // };

  // const saveBio = () => {
  //   if (user) {
  //     const userRef = db.collection("users").doc(user.uid);

  //     userRef
  //       .update({ bio: newBio })
  //       .then(() => {
  //         setIsEditingBio(false);
  //         setUserData({ ...userData, bio: newBio });
  //       })
  //       .catch((error) => {
  //         console.error("Error updating bio: ", error);
  //       });
  //   }
  // };

  useEffect(() => {
    if (user) {
      // Check if user.uid is defined before querying Firestore
      if (user.uid) {
        const reviewsRef = db
          .collection("reviews")
          .where("userUID", "==", user.uid)
          .orderBy("timestamp", "desc");

        reviewsRef.get().then((querySnapshot) => {
          const reviews = [];
          querySnapshot.forEach((doc) => {
            reviews.push({ id: doc.id, data: doc.data() });
          });
          setUserReviews(reviews);
        });
      }
    }
  }, [user]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const uniqueRestaurantNames = Array.from(
    new Set(userReviews.map((review) => review.data.restaurant))
  );

  const restaurantCounts = uniqueRestaurantNames.reduce(
    (counts, restaurant) => {
      const count = userReviews.filter(
        (review) => review.data.restaurant === restaurant
      ).length;
      counts[restaurant] = count;
      return counts;
    },
    {}
  );

  const handleRestaurantClick = (restaurantName) => {
    setClickedRestaurant(restaurantName);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setClickedRestaurant(null);
  };

  return (
    <Box className="user-profile-page">
      <ProfileHead user={user} userData={userData} />
      <div className="nav-bar">
        <Button
          variant="plain"
          className={activeSection === "reviews" ? "active" : ""}
          onClick={() => handleSectionChange("reviews")}
        >
          Reviews
        </Button>
        <Button
          variant="plain"
          className={` ${activeSection === "restaurants" ? "active" : ""}`}
          onClick={() => handleSectionChange("restaurants")}
        >
          Restaurants
        </Button>
      </div>

      {/* Display user's posts */}
      <div className="user-content">
        {activeSection === "reviews" && (
          <div className="user-reviews">
            {userReviews.map((review) => (
              <Review key={review.id} reviewData={review.data} />
            ))}
          </div>
        )}
        {activeSection === "restaurants" && (
          <div className="user-restaurants">
            {uniqueRestaurantNames.map((restaurantName, index) => (
              <div
                key={index}
                onClick={() => handleRestaurantClick(restaurantName)}
              >
                <RestaurantCard
                  restaurantName={restaurantName}
                  number={restaurantCounts[restaurantName]}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {showModal && clickedRestaurant && (
        <RestaurantModal
          onClose={handleCloseModal}
          restaurantName={clickedRestaurant}
          reviews={userReviews.filter(
            (review) => review.data.restaurant === clickedRestaurant
          )}
        />
      )}

      <BottomNavBar />
    </Box>
  );
};

export default UserAccount;
