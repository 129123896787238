import React from "react";
import PostReview from "../Components/PostReview";
import BottomNavbar from "../Components/BottomNavbar";

const ReviewPage = () => {
  return (
    <div>
      <PostReview />
      <BottomNavbar />
    </div>
  );
};

export default ReviewPage;
