// src/Components/TagsDisplay.jsx
import React from "react";
import { Box, Chip } from "@mui/material";
import { tagDefinitions } from "../utils/tagDefinitions";

const TagsDisplay = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  const tagMap = tagDefinitions.reduce((acc, tag) => {
    acc[tag.value] = tag.color;
    return acc;
  }, {});

  return (
    <Box
      sx={{
        width: "auto",
        display: "flex",
        overflowX: "auto",
        whiteSpace: "nowrap",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
      }}
    >
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tagDefinitions.find((def) => def.value === tag)?.label || tag}
          sx={{
            margin: "4px",
            backgroundColor: tagMap[tag],
            color: "#fff",
          }}
        />
      ))}
    </Box>
  );
};

export default TagsDisplay;
