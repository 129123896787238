import React from "react";
import { Button, FormControl, Box } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";

const PhotoUpload = ({ setPhotoFile, photoURL, setPhotoURL }) => {
  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPhotoFile(file);
      setPhotoURL(url);
    }
  };

  const handleRemovePhoto = () => {
    setPhotoFile(null);
    setPhotoURL(null);
  };

  return (
    <FormControl fullWidth>
      <Button
        variant="contained"
        component="label"
        startIcon={photoURL ? <DeleteIcon /> : <PhotoCamera />}
        onClick={photoURL ? handleRemovePhoto : undefined}
        sx={{
          backgroundColor: photoURL ? "red" : "primary.main",
          "&:hover": {
            backgroundColor: photoURL ? "darkred" : "primary.dark",
          },
        }}
        aria-label={photoURL ? "Remove photo" : "Upload photo"}
      >
        {photoURL ? "Choose a new photo" : "Upload"}
        {!photoURL && (
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handlePhotoUpload}
          />
        )}
      </Button>
      {photoURL && (
        <Box sx={{ mt: 2, position: "relative", maxWidth: "100%" }}>
          <img
            src={photoURL}
            alt="Uploaded Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "400px",
              borderRadius: "8px",
              objectFit: "contain",
            }}
          />
        </Box>
      )}
    </FormControl>
  );
};

export default PhotoUpload;
