import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const CitySearchBar = ({ onCitySelect }) => {
  const [uniqueCities, setUniqueCities] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const reviewsRef = db.collection("reviews");
      const querySnapshot = await reviewsRef.get();
      const cityNames = new Set();
      const reviews = [];
      querySnapshot.forEach((doc) => {
        const cityName = doc.data().city;
        const stateName = doc.data().state;
        if (cityName && stateName) {
          const cityStateName = `${cityName}, ${stateName}`;
          cityNames.add(cityStateName);
        }
        reviews.push({ id: doc.id, data: doc.data() });
      });
      setUniqueCities(Array.from(cityNames));
    };

    fetchReviews();
  }, []);

  const handleCityChange = (e, value) => {
    if (value) {
      onCitySelect(value);
    }
  };

  return (
    <Autocomplete
      id="city-autocomplete"
      disablePortal
      options={uniqueCities}
      autoHighlight
      onChange={handleCityChange}
      renderInput={(params) => <TextField {...params} label="City" />}
      sx={{ m: "auto", mt: 2, width: "70vw", maxWidth: 500 }}
    />
  );
};

export default CitySearchBar;
