import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/joy/Button";
import "./css/CopyLinkButton.css";

const CopyLinkButton = () => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    const currentURL = window.location.href;

    navigator.clipboard.writeText(currentURL).then(
      () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      },
      (err) => {
        console.error("Unable to copy to clipboard", err);
      }
    );
  };

  return (
    <Box>
      <Button className="profile-btn" variant="solid" onClick={copyToClipboard}>
        Copy Link
      </Button>
      {copySuccess && (
        <div className="chat-bubble">Link copied to clipboard!</div>
      )}
    </Box>
  );
};

export default CopyLinkButton;
