import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { UserAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Button, Box, TextField } from "@mui/material";

const SignIn = () => {
  const { user, setUser } = UserAuth();
  const auth = getAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateInputs = () => {
    let isValid = true;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email address is invalid");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }
    return isValid;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      setUser(user);
      navigate("/user-account");
    } catch (error) {
      console.log(`Error with Sign in: ${error.code}, ${error.message}`);
    }
  };

  useEffect(() => {
    if (user != null) {
      navigate("/user-account");
    }
  }, [user, navigate]);

  return (
    <Box className="form-container">
      <h2>Sign In</h2>
      <form className="form" onSubmit={handleSignIn}>
        <div className="form-group">
          <TextField
            label="Email"
            type="email"
            id="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <p className="error-message">{emailError}</p>}
        </div>
        <div className="form-group">
          <TextField
            label="Password"
            type="password"
            id="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && <p className="error-message">{passwordError}</p>}
        </div>
        <Button variant="contained" type="submit">
          Sign In
        </Button>
      </form>

      <p>
        Don't have an account yet?{" "}
        <Link className="form-link" to="/register">
          sign up
        </Link>
      </p>
    </Box>
  );
};

export default SignIn;
