// RegisterPage.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { UserAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Button, Box, TextField } from "@mui/material";

const RegisterUser = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const { setUser } = UserAuth();
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [displayNameError, setDisplayNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateInputs = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Email address is invalid");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (!displayName) {
      setDisplayNameError("Display name is required");
      isValid = false;
    } else {
      setDisplayNameError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!confirmPassword) {
      setConfirmPasswordError("Please confirm your password");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    return isValid;
  };
  const registerUser = async () => {
    if (!validateInputs()) {
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        updateProfile(user, {
          displayName: displayName,
        }).then(() => {
          setUser(user);
          console.log(`User Register:  ${user.displayName}`);
          navigate("/user-account");
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        setErrorMessage(error.message);
        console.log(`Error with Registration: ${errorCode}, ${errorMessage}`);
      });
  };

  return (
    <Box className="form-container">
      <h2>Sign Up</h2>
      <form className="form">
        <div className="form-group">
          <TextField
            label="email"
            type="email"
            id="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <p className="error-message">{emailError}</p>}
          {errorMessage && <p>{errorMessage}</p>}
        </div>
        <div className="form-group">
          <TextField
            label="Display Name"
            type="text"
            id="displayName"
            fullWidth
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          {displayNameError && (
            <p className="error-message">{displayNameError}</p>
          )}
        </div>
        <div className="form-group">
          <TextField
            label="Password"
            type="password"
            id="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && <p className="error-message">{passwordError}</p>}
        </div>
        <div className="form-group">
          <TextField
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {confirmPasswordError && (
            <p className="error-message">{confirmPasswordError}</p>
          )}
        </div>
        <Button variant="contained" type="submit" onClick={registerUser}>
          Create Account
        </Button>
      </form>
      <p>
        Already have an account?{" "}
        <Link to="/signin" className="form-link">
          sign in
        </Link>
      </p>
    </Box>
  );
};

export default RegisterUser;
