import React from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaPlusCircle,
  FaSearch,
  FaUserCircle,
  FaQuestionCircle,
} from "react-icons/fa";
import "./css/BottomNavbar.css";

const BottomNavbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/home-feed">
            <FaHome />
          </Link>
        </li>
        <li>
          <Link to="/search">
            <FaSearch />
          </Link>
        </li>
        <li>
          <Link to="/post-review">
            <FaPlusCircle />
          </Link>
        </li>
        <li>
          <Link to="/faq">
            <FaQuestionCircle />
          </Link>
        </li>
        <li>
          <Link to="/user-account">
            <FaUserCircle />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default BottomNavbar;
