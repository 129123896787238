import React, { useContext, createContext, useEffect, useState } from "react";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Set initial state to null to indicate no
  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await signOut(auth);
      navigate("/signin");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const usersCollection = db.collection("users");
        const userRef = usersCollection.doc(currentUser.uid);

        try {
          const userDoc = await userRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            if (!userData.handle) {
              // Handle doesn't exist, generate a new one
              const newUserDoc = {
                display_name: currentUser.displayName || "",
                handle: generateUniqueHandle(currentUser.displayName || ""),
                photoURL: currentUser.photoURL || "",
                user_posts: userData.user_posts || 0, // Keep existing posts count
                followers: userData.followers || 0, // Keep existing followers count
                following: userData.following || 0, // Keep existing following count
              };
              await userRef.set(newUserDoc, { merge: true });
            } else {
              // Handle exists, avoid overwriting
              setUser({ ...currentUser, handle: userData.handle });
            }
          } else {
            // If the user document doesn't exist, create it with a unique handle
            const newUserDoc = {
              display_name: currentUser.displayName || "",
              handle: generateUniqueHandle(currentUser.displayName || ""),
              photoURL: currentUser.photoURL || "",
              user_posts: 0,
              followers: 0,
              following: 0,
            };
            await userRef.set(newUserDoc, { merge: true });
          }
        } catch (error) {
          console.error("Error setting user document: ", error);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  function generateUniqueHandle(displayName) {
    const sanitizedDisplayName = displayName.replace(/\s+/g, "").toLowerCase();
    const uniqueString = Math.random().toString(36).substring(2, 8);
    return `${sanitizedDisplayName}_${uniqueString}`;
  }

  return (
    <AuthContext.Provider value={{ logOut, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
