import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BottomNavBar from "../Components/BottomNavbar";
import { UserAuth } from "../Context/AuthContext";
import { db } from "../firebase";
import "./css/SearchPage.css";

const SearchPage = () => {
  const { user } = UserAuth();
  const [userData, setUserData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [randomUsers, setRandomUsers] = useState([]);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (user) {
      const userRef = db.collection("users").doc(user.uid);

      userRef.get().then((doc) => {
        if (doc.exists) {
          setUserData(doc.data());
        }
      });
    }
  }, [user]);

  const userHandle = userData ? userData.handle : "";

  useEffect(() => {
    // Fetch 8 random users when the component mounts
    const fetchRandomUsers = async () => {
      const usersRef = db.collection("users");
      const randomUsersSnapshot = await usersRef
        .orderBy("handle")
        .limit(8)
        .get();

      const randomUsersData = [];
      randomUsersSnapshot.forEach((doc) => {
        const userData = doc.data();
        randomUsersData.push({ id: doc.id, ...userData });
      });

      setRandomUsers(randomUsersData);
    };

    fetchRandomUsers();
  }, []);

  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      // If the search bar is empty, display the 8 random users
      setSearchResults(
        randomUsers.filter((user) => user.handle !== userHandle)
      );
    } else {
      // Otherwise, fetch users from Firestore based on the search term
      const usersRef = db.collection("users");
      const query = searchTerm.toLowerCase();
      const searchResultsSnapshot = await usersRef
        .where("handle", ">=", query)
        .where("handle", "<=", query + "\uf8ff")
        .get();

      const searchResultsData = [];
      searchResultsSnapshot.forEach((doc) => {
        const userData = doc.data();
        if (doc.handle !== userHandle) {
          searchResultsData.push({ id: doc.id, ...userData });
        }
      });

      setSearchResults(searchResultsData);
    }

    setShowList(true);
  };

  const handleBarClick = async () => {
    // Display 8 random users when the search bar is clicked
    setSearchResults(randomUsers.filter((user) => user.handle !== userHandle));
    setShowList(true);
  };

  return (
    <div>
      <div className="search-container">
        <input
          className="search-bar"
          type="text"
          placeholder="Search for users..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch();
          }}
          onClick={handleBarClick}
        />

        {showList && (
          <div className="search-results">
            {searchResults.map((user) => (
              <Link key={user.id} to={`/account/${user.id}`}>
                <div>{user.handle}</div>
              </Link>
            ))}
          </div>
        )}
      </div>
      <BottomNavBar />
    </div>
  );
};

export default SearchPage;
