// RestaurantModal.jsx

import React from "react";
import Review from "../Components/Review";
import "./css/RestaurantModal.css";

const RestaurantModal = ({ onClose, restaurantName, reviews }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          x
        </button>
        <h2>{restaurantName}</h2>
        <div className="modal-reviews">
          {reviews.map((review) => (
            <Review key={review.id} reviewData={review.data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RestaurantModal;
